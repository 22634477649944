import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from './pages/home/Home';
import PlayerPage from './pages/player/Player';
import { PlayersRankingContext } from './providers/PlayersRankingContext';
import { loadSpreadsheet } from './helpers/spreadsheet';


const App: React.FC = () => {
  const [rankingState, setRankingState] = useState<any[]>([]);

  useEffect(() => {
    loadSpreadsheet((data: any[]) => {
      setRankingState(data);

      setTimeout(() => {
        console.log(rankingState);
      }, 1000);
    });
  }, []);

  return (
    <Router>
      <PlayersRankingContext.Provider value={rankingState}>
        <div className="ui container">
          <Route path="/" exact component={HomePage} />
          <Route path="/players/:name" component={PlayerPage} />
        </div>
      </PlayersRankingContext.Provider>
    </Router>
  );
}

export default App;
