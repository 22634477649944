import React from 'react';
import { Link } from 'react-router-dom';

const StatsTable: React.FC<StatsTableProps> = (props) => {
    function toFix(nr: number) {
        return nr && nr.toFixed(2);
    }

    function toFixPerc(nr: number) {
        return toFix(nr) + "%";
    }

    const nameElement = props.hasPlayerLink ? (
        <Link to={`/players/${props.rankings.name}`}>
            {props.rankings.name}
        </Link>
    ) : (<span>{props.rankings.name}</span>);

    return (
        <table className="ui celled table">
            <thead>
                <tr>
                    <th>
                        Meciuri jucate
          </th>
                    <th>
                        Win rate
          </th>
                    <th>
                        Draw rate
          </th>
                    <th>
                        Lose rate
          </th>
                    <th className="ui center aligned" colSpan={2} title="Goluri marcate | Goluri marcate per meci">
                        GM | GM/m
          </th>
                    <th className="ui center aligned" colSpan={2} title="Goluri primite | Goluri primite per meci">
                        GP | GP/m
          </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        {props.rankings.PLD}
                    </td>
                    <td>
                        {toFixPerc(props.rankings.WR)}
                    </td>
                    <td>
                        {toFixPerc(props.rankings.DR)}
                    </td>
                    <td>
                        {toFixPerc(props.rankings.LR)}
                    </td>
                    <td className="ui center aligned">
                        {props.rankings.GS}
                    </td>
                    <td className="ui center aligned">
                        {toFix(props.rankings.GSR)}
                    </td>
                    <td className="ui center aligned">
                        {props.rankings.GR}
                    </td>
                    <td className="ui center aligned">
                        {toFix(props.rankings.GRR)}
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export interface StatsTableProps {
    rankings: any;
    hasPlayerLink: boolean;
}

export default StatsTable;