import React from 'react';
import "./Preloaded.css";

const Preloaded: React.FC<PreloadedProps> = (props) => {

    return (
        <div>
            <div className={`Preloaded-Loader ui segment ${props.isLoading ? "" : "Is-Hidden"}`}>
                <div className="ui active inverted dimmer">
                    <div className="ui text loader">Loading</div>
                </div>
                <p></p>
            </div>

            <div className={`Preloaded-ActualContent ${props.isLoading ? "Is-Hidden" : ""}`}>
                {props.children}
            </div>
        </div>
    )
}

export interface PreloadedProps {
    isLoading: boolean;
}

export default Preloaded;