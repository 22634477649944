import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { PlayersRankingContext } from '../../providers/PlayersRankingContext';
import Preloaded from '../../components/preloaded/Preloaded';

const HomePage: React.FC = () => {
  const clasamentContext = useContext<any[]>(PlayersRankingContext);
  const [clasament, setClasament] = useState<any[]>([]);
  const [teamsUsed, setTeamsUsed] = useState<any[]>([]);

  useEffect(() => {
    mapClasamentData(clasamentContext);
  }, [clasamentContext]);

  function mapClasamentData(data: any[]) {
    const playerMap = {} as any;
    const teamsMap = {} as any;

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      const player1Name = element["Nume 1"];
      const player2Name = element["Nume 2"];

      if (!playerMap[player1Name]) {
        playerMap[player1Name] = intiPlayerMap();
      }

      if (!playerMap[player2Name]) {
        playerMap[player2Name] = intiPlayerMap();
      }

      const player1 = playerMap[player1Name];
      const player2 = playerMap[player2Name];

      const team1 = (element["Echipa 1"] || "").trim();
      const team2 = (element["Echipa 2"] || "").trim();

      if (!teamsMap[team1]) {
        teamsMap[team1] = {};
        teamsMap[team1].__total = 0;
      }

      teamsMap[team1].__total += 1;

      if (!teamsMap[team1][player1Name]) {
        teamsMap[team1][player1Name] = 0;
      }

      teamsMap[team1][player1Name] += 1;

      if (!teamsMap[team2]) {
        teamsMap[team2] = {};
        teamsMap[team2].__total = 0;
      }

      teamsMap[team2].__total += 1;

      if (!teamsMap[team2][player2Name]) {
        teamsMap[team2][player2Name] = 0;
      }

      teamsMap[team2][player2Name] += 1;

      player1.PLD += 1;
      player2.PLD += 1;

      const score1 = parseInt(element["Scor 1"], 10);
      const score2 = parseInt(element["Scor 2"], 10);

      // player 1 won
      if (score1 > score2) {
        player1.W += 1;
        player2.L += 1;
      }
      // player 2 won
      else if (score1 < score2) {
        player2.W += 1;
        player1.L += 1;
      }
      // draw
      else {
        player1.D += 1;
        player2.D += 1;
      }

      player1.GS += score1;
      player2.GR += score1;

      player1.GR += score2;
      player2.GS += score2;
    }

    patchTeamsMap(teamsMap);

    const playerNames = Object.keys(playerMap);
    const playersRanking: any[] = [];

    for (let y = 0; y < playerNames.length; y++) {
      const name = playerNames[y];
      const stats = playerMap[name];
      const pld = stats.PLD;

      // win/lose/draw rates
      stats.WR = (stats.W / pld) * 100;
      stats.LR = (stats.L / pld) * 100;
      stats.DR = (stats.D / pld) * 100;

      // goal scores/received rates
      stats.GSR = stats.GS / pld;
      stats.GRR = stats.GR / pld;

      // adding the name of the player
      stats.name = name;

      playersRanking.push(stats);
    }

    playersRanking.sort((a, b) => b.WR - a.WR);

    setClasament(playersRanking);
  }

  function patchTeamsMap(teamsMap: any) {
    var teamNms = Object.keys(teamsMap);
    var tms = [];

    for (let i = 0; i < teamNms.length; i++) {
      const tm = teamNms[i];
      tms.push(Object.assign({
        __name: tm
      }, teamsMap[tm]));
    }

    tms.sort((a, b) => b.__total - a.__total);

    setTeamsUsed(tms);
  }

  function intiPlayerMap() {
    return {
      W: 0,
      D: 0,
      L: 0,
      GS: 0,
      GR: 0,
      PLD: 0
    };
  }

  function toFix(nr: number) {
    return nr && nr.toFixed(2);
  }

  function toFixPerc(nr: number) {
    return toFix(nr) + "%";
  }

  return (
    <div className="App">
      <div></div>
      <h1 className="ui huge header AppHeader">
        Clasament
      </h1>

      <Preloaded isLoading={!clasament.length}>
        <table className="ui celled table">
          <thead>
            <tr>
              <th>
                Pozitie
          </th>
              <th>
                Nume
          </th>
              <th>
                Meciuri jucate
          </th>
              <th>
                Win rate
          </th>
              <th>
                Draw rate
          </th>
              <th>
                Lose rate
          </th>
              <th className="ui center aligned" colSpan={2} title="Goluri marcate | Goluri marcate per meci">
                GM | GM/m
          </th>
              <th className="ui center aligned" colSpan={2} title="Goluri primite | Goluri primite per meci">
                GP | GP/m
          </th>
            </tr>
          </thead>

          <tbody>
            {clasament.map((elem, index) => {
              return (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    <Link to={`/players/${elem.name}`}>
                      {elem.name}
                    </Link>
                  </td>
                  <td>
                    {elem.PLD}
                  </td>
                  <td>
                    {toFixPerc(elem.WR)}
                  </td>
                  <td>
                    {toFixPerc(elem.DR)}
                  </td>
                  <td>
                    {toFixPerc(elem.LR)}
                  </td>
                  <td className="ui center aligned">
                    {elem.GS}
                  </td>
                  <td className="ui center aligned">
                    {toFix(elem.GSR)}
                  </td>
                  <td className="ui center aligned">
                    {elem.GR}
                  </td>
                  <td className="ui center aligned">
                    {toFix(elem.GRR)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Preloaded>

      <div>
        <div></div>
        <h1 className="ui huge header AppHeader">
          Echipe folosite
      </h1>
        <Preloaded isLoading={!clasament.length}>
          <table className="ui celled table">
            <thead>
              <tr>
                <th>
                  Team
              </th>

                {clasament.map((clasEntry, i) => {
                  return (
                    <th key={i}>
                      {clasEntry.name}
                    </th>
                  )
                })}

                <th>
                  Total
              </th>
              </tr>
            </thead>

            <tbody>
              {teamsUsed.map((teamUsed, y) => {
                return (
                  <tr key={y}>
                    <td>
                      {teamUsed.__name}
                    </td>

                    {clasament.map((clasEntry, z) => {
                      console.log(teamUsed, clasEntry.name);
                      const plMatchCount = teamUsed[clasEntry.name] as number | undefined;

                      return (
                        <td key={z}>
                          {plMatchCount ? plMatchCount : 0}
                        </td>
                      )
                    })}

                    <td>
                      <strong>
                        {teamUsed.__total}
                      </strong>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Preloaded>
      </div>
    </div>
  );
}

export default HomePage;
