import React, { useContext, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { PlayersRankingContext } from "../../providers/PlayersRankingContext";
import StatsTable from "../../components/stats-table/StatsTable";


export interface PlayerPageParams {
    name?: string;
}

const PlayerPage = ({ match }: RouteComponentProps<PlayerPageParams>) => {
    const clasamentContext = useContext<any[]>(PlayersRankingContext);
    const [mainPlayerName, setMainPlayerName] = useState<string>((match.params.name || "").trim());
    const [playersRankings, setPlayersRankings] = useState<any[]>([]);

    useEffect(() => {
        mapRankings(clasamentContext);
    }, [clasamentContext]);

    function initPlayerMap() {
        return {
            W: 0,
            D: 0,
            L: 0,
            GS: 0,
            GR: 0,
            PLD: 0
        } as any;
    }

    function mapRankings(fbMatches: any[]) {
        const rankMap = {} as any;

        for (let i = 0; i < fbMatches.length; i++) {
            const fbMatch = fbMatches[i];

            const playerName1 = fbMatch["Nume 1"];
            const playerName2 = fbMatch["Nume 2"];

            // match that the main player did not take part in, we just continue..
            if ([playerName1, playerName2].indexOf(mainPlayerName) === -1) {
                continue;
            }

            const mainPlayerIndex = playerName1 === mainPlayerName ? 1 : 2;
            const otherPlayerIndex = playerName1 === mainPlayerName ? 2 : 1;

            const otherPlayerName = fbMatch[["Nume ", (otherPlayerIndex).toString()].join("")];

            if (!rankMap[otherPlayerName]) {
                rankMap[otherPlayerName] = initPlayerMap();
            }

            rankMap[otherPlayerName].PLD += 1;

            const scoreMain = parseInt(fbMatch[["Scor ", (mainPlayerIndex).toString()].join("")], 10);
            const scoreOther = parseInt(fbMatch[["Scor ", (otherPlayerIndex).toString()].join("")], 10);

            if (scoreMain > scoreOther) {
                rankMap[otherPlayerName].W += 1;
            } else if (scoreMain < scoreOther) {
                rankMap[otherPlayerName].L += 1;
            } else {
                rankMap[otherPlayerName].D += 1;
            }

            rankMap[otherPlayerName].GS += scoreMain;
            rankMap[otherPlayerName].GR += scoreOther;
        }

        const otherPlayersNames = Object.keys(rankMap);
        const _playersRankings = [];

        for (let z = 0; z < otherPlayersNames.length; z++) {
            const otherPlayerName = otherPlayersNames[z];

            const stats = rankMap[otherPlayerName];
            const pld = stats.PLD;

            // win/lose/draw rates
            stats.WR = (stats.W / pld) * 100;
            stats.LR = (stats.L / pld) * 100;
            stats.DR = (stats.D / pld) * 100;

            // goal scores/received rates
            stats.GSR = stats.GS / pld;
            stats.GRR = stats.GR / pld;

            _playersRankings.push({
                name: otherPlayerName,
                stats: stats
            });
        }

        setPlayersRankings(_playersRankings);
    }

    if (!mainPlayerName) {
        return (
            <div className="App">
                <strong>Unknown player</strong>: {mainPlayerName}
            </div>
        );
    }

    return (
        <div className="App">
            <div></div>
            <h1 className="ui huge dividing header">
                <strong>{match.params.name}</strong>
            </h1>

            {playersRankings.map((elem, index) => {
                return (
                    <div key={index}>
                        <div></div>
                        <h2 className="ui header">
                            {elem.name}
                        </h2>

                        <div>
                            <StatsTable
                                hasPlayerLink={false}
                                rankings={elem.stats} />
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default PlayerPage;